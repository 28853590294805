<script setup lang="ts">
withDefaults(
	defineProps<{
		locationTid?: string | boolean | null;
	}>(),
	{ locationTid: null }
);

const { t } = useT();
const { open, close } = useAppModals();
const { data: appInitData } = useAppInitData();
const { refresh } = useHomePage();

const errorMsgs = {
	notValid: t("Form not valid"),
	notCompleted: t("Must be completed")
};

const pageLoading = ref(true);

const formData = computed(
	() =>
		appInitData.value?.registrationFormData || {
			emailEnabled: true,
			phoneEnabled: true,
			facebookAuthEnabled: true,
			googleAuthEnabled: true,
			contactsOrder: ["email", "phone"]
		}
);

const { buttons: enabledButtons } = useAuthorizationSocialButtons(formData);

const {
	email,
	password,
	isShowPassword,
	signupLoading,
	acceptTerms,
	acceptRestrictedStates,
	error,
	errorMessage,
	isAcceptTermsError,
	isAcceptRestrictedStatesError,
	isButtonEnabled,
	isEmailShowError,
	isEmailTouched,
	isPasswordShowError,
	isPasswordTouched,
	handleSubmit,
	handleInput
} = useSignup(errorMsgs, open, close);

onMounted(() => {
	pageLoading.value = false;
});

const handleSignup = async () => {
	await handleSubmit();
	await refresh();
};
</script>

<template>
	<div class="auth">
		<div class="mobile-social-wrapper mobile">
			<MAuthorizationSocialButtons :socials="enabledButtons" entryPoint="registration" buttonSize="md" />
			<AText class="by-login" variant="ternopil" :modifiers="['uppercase', 'bold', 'center']" as="div">
				{{ t("or login by") }}
			</AText>
		</div>
		<form autocomplete="off" class="form" @submit.prevent="handleSignup">
			<div class="labels">
				<label class="row-input">
					<MEmailInput
						v-model="email"
						:invalid="isEmailShowError"
						autocomplete="username"
						clear
						class="field"
						:location-tid="locationTid && `${locationTid}-email`"
						:disabled="pageLoading"
						:placeholder="t('Enter your Email')"
						@blur="isEmailTouched = true"
						@input="handleInput('email')"
					/>
					<AAnimationSlide v-if="isEmailShowError" :active="isEmailShowError">
						<AText class="invalid" variant="topeka" :data-tid="locationTid && `${locationTid}-email-validation`">
							{{ errorMessage || t("Please provide a valid email") }}
						</AText>
					</AAnimationSlide>
				</label>
				<label v-if="isShowPassword" class="row-input">
					<MPasswordInput
						v-model="password"
						:invalid="isPasswordShowError"
						autocomplete="current-password"
						:placeholder="t('Enter your Password')"
						is-svg
						class="field"
						:disabled="pageLoading"
						:location-tid="locationTid && `${locationTid}-password`"
						@blur="isPasswordTouched = true"
						@input="handleInput('password')"
					/>
					<AAnimationSlide v-if="isPasswordShowError" :active="isPasswordShowError">
						<AText class="invalid" variant="topeka" :data-tid="locationTid && `${locationTid}-password-validation`">
							{{ password.length ? t("Password must be at least 6 characters long") : t("Please create a password") }}
						</AText>
					</AAnimationSlide>
				</label>
			</div>

			<div class="row-checkbox">
				<MCheckbox
					v-model="acceptRestrictedStates"
					:data-tid="locationTid && `${locationTid}-age-confirm-chk`"
					no-animation
					:disabled="pageLoading"
					:invalid="isAcceptRestrictedStatesError"
					@input="handleInput('acceptRestrictedStates')"
				>
					<AText class="checkbox-text text-neutral-300" :size="12" as="div" :class="{ disabled: pageLoading }">
						<i18n-t keypath="I am at least 18 years old and I am not a resident of the {key1}.">
							<template #key1>
								<NuxtLink
									class="link"
									target="_blank"
									to="/page/agreement/#restricted"
									:data-tid="locationTid && `${locationTid}-age-rules-link`"
								>
									<AText class="text-primary-400" :size="12" :modifiers="['link', 'underline']">
										{{ t("Restricted states") }}
									</AText>
								</NuxtLink>
							</template>
						</i18n-t>
					</AText>
				</MCheckbox>
				<MCheckbox
					v-model="acceptTerms"
					:disabled="pageLoading"
					no-animation
					:invalid="isAcceptTermsError"
					:data-tid="locationTid && `${locationTid}-terms-confirm-chk`"
					@input="handleInput('acceptTerms')"
				>
					<AText :size="12" as="div" class="checkbox-text text-neutral-300" :class="{ disabled: pageLoading }">
						<i18n-t
							keypath="I accept the Funrize the {key1} and {key2} (including, for the California residents the {key3}."
						>
							<template #key1>
								<NuxtLink
									class="link"
									target="_blank"
									to="page/agreement"
									:data-tid="locationTid && `${locationTid}-agreement-rules-link`"
								>
									<AText class="text-primary-400" :size="12" :modifiers="['link', 'underline']">
										{{ t("Terms of Use") }}
									</AText>
								</NuxtLink>
							</template>
							<template #key2>
								<NuxtLink
									class="link"
									target="_blank"
									to="page/privacy"
									:data-tid="locationTid && `${locationTid}-privacy-rules-link`"
								>
									<AText class="text-primary-400" :size="12" :modifiers="['link', 'underline']">
										{{ t("Privacy Policy") }}
									</AText>
								</NuxtLink>
							</template>
							<template #key3>
								<NuxtLink
									class="link"
									target="_blank"
									to="page/california-privacy-notice"
									:data-tid="locationTid && `${locationTid}-california-rules-link`"
								>
									<AText class="text-primary-400" :size="12" :modifiers="['link', 'underline']">
										{{ t("California Privacy Notice") }}
									</AText>
								</NuxtLink>
							</template>
						</i18n-t>
					</AText>
				</MCheckbox>
				<AAnimationSlide v-if="error === 'agree'" :active="error === 'agree'" class="checkbox-error">
					<AText class="invalid" variant="topeka" :data-tid="locationTid && `${locationTid}-rules-disagree-error`">
						{{ t("Must be completed") }}
					</AText>
				</AAnimationSlide>
			</div>
			<div class="buttons">
				<AButton
					:modifiers="[!isButtonEnabled ? 'disabled' : '']"
					type="submit"
					variant="primary"
					size="xl"
					class="submit"
					:data-tid="locationTid && `${locationTid}-create-account-btn`"
				>
					<AAnimationSpin v-if="signupLoading || pageLoading">
						<ASvg class="spinner" name="loading/spinner" />
					</AAnimationSpin>
					<AText v-else :modifiers="['medium']" variant="toledo">{{ t("Join now") }}</AText>
				</AButton>
			</div>
		</form>
		<MAuthorizationSocialButtons
			:socials="enabledButtons"
			location-tid="home"
			entryPoint="registration"
			is-small
			class="socials-mob desktop"
		/>
	</div>
</template>

<style scoped lang="scss">
.flex-column {
	display: flex;
	flex-direction: column;
}

.form {
	@extend .flex-column;
	gap: 16px;

	@include media-breakpoint-up(md) {
		gap: 24px;
	}
}

.labels {
	@extend .flex-column;
	gap: 16px;

	@include media-breakpoint-up(lg) {
		flex-direction: row;
	}
}

.spinner {
	font-size: 28px;
}
.buttons {
	@include media-breakpoint-up(md) {
		display: flex;
		gap: 16px;
	}
	.submit {
		width: 296px;
	}
	.row-social {
	}
}

.row-input {
	@extend .flex-column;
	gap: 4px;
	@include media-breakpoint-up(md) {
		width: 296px;
	}
}

.row-checkbox {
	@extend .flex-column;
	gap: 4px;

	@include media-breakpoint-down(md) {
		gap: 8px;
	}
}

.invalid {
	color: var(--danger-500);
}
.checkbox-text {
	line-height: 20px;

	&.disabled {
		opacity: 0.5;
	}
}

.mobile-social-wrapper {
	width: 100%;
}
.by-login {
	margin: 4px 0;
	position: relative;
	color: var(--neutral-300);
	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		width: calc(50% - 40px);
		height: 1px;
		background: var(--neutral-800);
	}
	&::before {
		left: 0;
	}
	&::after {
		right: 0;
	}
}
//---------

.auth {
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	max-width: 624px;
	position: relative;
	z-index: 2;
	margin-top: 16px;
	@include media-breakpoint-down(md) {
		background: var(--neutral-900);
		padding: 16px;
		border-radius: 16px;
		margin-bottom: 16px;
		margin: 16px auto 0;
	}
	& :deep(.submit) {
		min-width: 296px;
		max-width: 296px;
		@include media-breakpoint-down(md) {
			margin: 0 auto;
		}
	}
	@include media-breakpoint-up(md) {
		.socials-mob {
			position: absolute;
			bottom: 0;
			left: 316px;
		}
		:deep(.row-social.small) {
			display: flex;
		}
	}
}
</style>
